import React from 'react'
import { graphql, Link } from 'gatsby'
import { Seo } from '../components/base'
import { Layout } from '../components'
import Modal from 'react-modal'
import { BioButton, Button, LayoutBackground } from '../components/shared'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Offer } from '../components/offers/Offer'
import { getLocaleMarket } from '../utils/getLocaleMarket'
import * as extern from '../css/organism/bioOfferCard.module.scss'
import { getLocale, getLocaleUrl } from '../utils/localeUtils'
import classNames from 'classnames'

import * as styles from './../css/organism/bioNewProductFeature.module.scss'

Modal.setAppElement('#___gatsby')

const NewProductFeature = ({ data, pageContext }) => {
  const languages = { pageContext }
  const pageData = data.newProductFeaturePage.nodes[0]
  const [initialOffer, ...remainingOffers] = data.newProductFeatures.nodes

  const availableItem = !!initialOffer

  let market = getLocaleMarket()

  const layoutBackgroundHeaderComponent = (
    <h1 className="mb-14 md:mb-24">{pageData.headline}</h1>
  )
  return (
    <Layout standardPaddingTopFromNavbarToMain translations={languages}>
      <Seo
        title={pageData.seo?.metaTitle}
        description={pageData.seo?.metaDescription}
        image={pageData.seo?.ogImage?.asset?.url}
      />
      <LayoutBackground
        headerComponent={layoutBackgroundHeaderComponent}
        className="full-width mb-16 md:mb-32">
        <section className={styles.bioNewProductFeature}>
          <div className="newCards">
            <Offer
              offer={initialOffer}
              market={market}
              isNeuImSortiment={true}
              neuPage={true}
            />
            <OfferTeaser
              headline={pageData.offerTeaserHeadline}
              image={pageData.offerTeaserImage?.image}
              market={market}
            />
            {remainingOffers.map((item, index) => (
              <Offer
                offer={item}
                key={index}
                market={market}
                isNeuImSortiment
              />
            ))}
          </div>
          {availableItem ? (
            <p className={classNames(styles.__legal, 'bioBodyLegal')}>
              {pageData.disclaimer}
            </p>
          ) : (
            ''
          )}
        </section>
      </LayoutBackground>
    </Layout>
  )
}

export default NewProductFeature

const OfferTeaser = ({ headline, image, market }) => {
  const offerSlug = market ? `/${market?.slug}/angebote` : '/angebote'
  const locale = getLocale()
  return (
    <div className={classNames('newCard', extern.bioOfferCard)}>
      <div className={(extern.__offerWrapper, extern.noBorder)}>
        <Link to={offerSlug}>
          <BgImage
            Tag="div"
            image={getImage(image?.asset)}
            alt={image?.alt}
            className="flex justify-center items-center w-full h-full">
            <h2 className="text-xl text-white text-center headline-text-shadow-bold font-headline p-1">
              {headline}
            </h2>
            <div className="w-full text-center absolute bottom-10 pb-24">
              <BioButton
                button={{
                  buttonCta: `Zu den Angeboten`,
                  customPath: getLocaleUrl(locale, '/angebote')
                }}
                style="isSmall isWide"
              />
            </div>
          </BgImage>
        </Link>
      </div>
    </div>
  )
}

export const marketSelectionQuery = graphql`
  query ($language: String! = "de_de", $isInAustria: Boolean! = false) {
    newProductFeaturePage: allSanityNewProductFeaturePage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        subtitle
        seo {
          ...SeoQuery
        }
        headline
        disclaimer
        offerTeaserHeadline
        offerTeaserImage {
          ...ImageObjectQuery
        }
      }
    }
    newProductFeatures: allSanityNewProductFeature(
      filter: { isInAustria: { eq: $isInAustria } }
    ) {
      nodes {
        isInAustria
        image
        offerImage {
          ...ImageObjectQuery
        }
        brand
        articleGroup {
          productGroup {
            title
          }
        }
        price
        priceb
        pricec
        shordesc
        vegetarian
        vegan
        lactosefree
        glutenfree
        deposit
        drained
        title
        subtitle
        labels {
          title
          image {
            ...ImageObjectQuery
          }
        }
      }
    }
  }
`
